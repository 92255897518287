import React from 'react'
import SEO from 'Components/seo'
import Navbar from 'Components/navbar'
import 'SCSS/contact.scss'
import Microphone from './components/microphone'

// === ICONS === //
import gmail from './images/icons8-gmail.svg'
import github from './images/icons8-github.svg'
import twitter from './images/icons8-twitter.svg'
import linkedin from './images/icons8-linkedin.svg'


const ContactPage = props => {
	console.log(gmail);
	
	return (
		<div className="page">
			<SEO title="Contact" url='/contact' />
			<Navbar from="/contact" />
			<div className="page-main" aria-labelledby='contact-title contact-list'>
				<h1 id='contact-title' style={{display: 'none'}}>Ways to reach me:</h1>
				<ul id='contact-list' className='contact-container'>
					<Microphone src={gmail} alt={'Email me at gino.valente.business@gmail.com'} url={'mailto:gino.valente.business@gmail.com'} standWidth={0.05} className='mic' />
					<Microphone src={github} alt={'Visit my GitHub'} url={'https://github.com/MrGVSV'} flip rotation={10} standWidth={0.025} className='mic' />
					<Microphone src={twitter} alt={'Follow my Twitter'} url={'https://twitter.com/TheGinoValente'} rotation={-30} standWidth={0.02} className='mic' />
					<Microphone src={linkedin} alt={'Connect with me on LinkedIn'} url={'https://www.linkedin.com/in/gino-t-valente/'} flip rotation={-20} standWidth={0.035} className='mic' />
				</ul>
			</div>
		</div>
	)
}

export default ContactPage
