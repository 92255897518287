import React, { HTMLAttributes, useRef, useEffect, useState, MutableRefObject } from 'react'
import nextId from 'react-id-generator'
import styles from './microphone.module.scss'

import { smartTimeline } from '../../../utils/animations/control';

interface IProps extends HTMLAttributes<HTMLElement> {
    standWidth: number;
    popAmount: string;
    rotation: number;
    flip: boolean;
    src: string;
    url: string;
    alt: string;
}

const EASE = 'expo.out';
const DUR = 0.35;

export default function Microphone({ className='', style, src, url, alt, standWidth=0.1, popAmount='-5%', rotation=0, flip, ...etc }: IProps) {

    const boxRef: MutableRefObject<SVGRectElement | undefined> = useRef();
    const micRef: MutableRefObject<SVGRectElement | undefined> = useRef();

    const groupId: string = nextId('mic-group--');
    const titleId: string = nextId('mic-title--');

    const [standTop, setStandTop] = useState(0);
    const [micWidth, setMicWidth] = useState(0);

    const [isHovering, setIsHovering] = useState(false);

    const getCenter = () => {
        if(!micRef.current){
            setStandTop(0); 
            return;
        }
        let bbox = micRef.current.getBoundingClientRect();
        if(standTop !== bbox.height/2) {
            setStandTop(bbox.height/2);
        }
    }

    const getWidth = () => {
        if(!boxRef.current){
            setMicWidth(0); 
            return;
        }
        let bbox = boxRef.current.getBoundingClientRect();
        if(standWidth !== bbox.width) {
            setMicWidth(bbox.width);
        }
    }

    useEffect(() => {
        getCenter();
        getWidth();
        window.addEventListener('resize', getCenter, false);
        window.addEventListener('resize', getWidth, false);

        return () => {
            window.removeEventListener('resize', getCenter, false);
            window.removeEventListener('resize', getWidth, false);
        };
    })

    function popUp(_e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        setIsHovering(true);
        let timeline = smartTimeline();
        timeline.to(`#${groupId}`, {y: popAmount, duration: DUR, ease: EASE})
    }
    function popDown(_e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        setIsHovering(false);
        let timeline = smartTimeline();
        timeline.to(`#${groupId}`, {y: '0%', duration: DUR, ease: EASE})
    }

	return (
		<li className={`${className} ${styles.micContainer} ${flip ? styles.flipped : ''}`} style={style} {...etc}>
            <svg className={`${styles.micStand} ${isHovering ? styles.glow : ''}`} viewBox={`0 0 ${micWidth * standWidth} 100`}
				xmlns="http://www.w3.org/2000/svg" style={{top: `${standTop}px`}}>
                <rect x='0' y='0' width={micWidth * standWidth} height='100'
                vectorEffect="non-scaling-stroke" />
            </svg>
			<svg
                className={`${styles.micSvg} ${isHovering ? styles.glow : ''}`}
				viewBox="0 -50 302 422"
                xmlns="http://www.w3.org/2000/svg"
                onMouseEnter={popUp} onMouseLeave={popDown}
                transform={`rotate(${rotation})`}
                ref={micRef}
                aria-labelledby={titleId}
			>
                <a href={url}>
                <title id={titleId}>{alt}</title>
				<g id={groupId} className={`${styles.micGroup}`}>
					<path
						className={styles.micHead}
						d="M150.5 76C150.5 117.701 116.918 151.5 75.5 151.5C34.0817 151.5 0.5 117.701 0.5 76C0.5 34.2994 34.0817 0.5 75.5 0.5C116.918 0.5 150.5 34.2994 150.5 76Z"
					/>
					<path
						className={styles.micBody}
						d="M150.644 78.121C151.143 96.9783 156.234 105.73 157.928 107.532L299.306 328.342C308.084 349.029 256.591 388.232 235.078 362.817C235.078 362.817 126.675 233.496 70.0782 163.018C66.4147 157.808 56.4502 150.77 51 147.5C51.1825 76.7376 150.144 59.2638 150.644 78.121Z"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						className={styles.micBodyStroke}
						d="M299.306 328.342L157.928 107.532C156.234 105.73 151.143 96.9783 150.644 78.121C150.144 59.2638 51.1825 76.7376 51 147.5C56.4502 150.77 66.4147 157.808 70.0782 163.018C126.675 233.496 235.078 362.817 235.078 362.817M299.306 328.342C273.597 306.798 231.885 340.32 235.078 362.817M299.306 328.342C308.084 349.029 256.591 388.232 235.078 362.817"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
                        className={styles.micBox}
                        ref={boxRef}
						d="M63 171.429L174.5 107L221 166.5V190.251V195.48L217 198L214.5 194C169 200 150 231.5 145 239.5C141 245.9 143.333 253.167 145 256L114.272 276L63 222.669V171.429Z"
					/>
					<path
						className={styles.micBoxStroke}
						d="M63 171.429L174.5 107L221 166.5M63 171.429L114.272 233.126M63 171.429V222.669L114.272 276M221 166.5L114.272 233.126M221 166.5V190.251V195.48L217 198L214.5 194C169 200 150 231.5 145 239.5C141 245.9 143.333 253.167 145 256L114.272 276M114.272 233.126V276"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
                    <g className={styles.micIconGroup}>
                        <image className={`${styles.micIcon} ${flip ? styles.flipped : ''}`} href={src} x='-37.5' y='-37.5' width='75' height='75' />
                    </g>
				</g>
                </a>
			</svg>
		</li>
	)
}